import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Password from "src/components/Password";
import ChoosePlan from "src/components/choose-plan";
import { styled } from "@mui/material/styles";

import { FormProvider, RHFCheckbox, RHFSwitch } from "src/components/hook-form";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import EnterOtp from "./components/enter-otp";
import ErrorBanner from "./components/error-banner";
import useLogin from "./hooks/use-login";
import "./style.css";
import Iconify from "src/components/Iconify";
import RHFTextFieldForm from "src/components/hook-form/RHFTextFieldForm";
import GoogleOAuthButton from "src/pages/auth/components/google-login";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36, // Reduced width to make the switch smaller
  height: 22, // Reduced height for a smaller switch
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(14px)", // Adjusted the translation for smaller size
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6b4dff", // Set the checked background to #6b4dff
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[600]
          : theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "dark" ? 0.3 : 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18, // Reduced width of the thumb
    height: 18, // Reduced height of the thumb
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2, // Adjusted to match the new size
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const LoginForm = () => {
  const { secret, onSubmit, methods } = useLogin();

  const {
    watch,
    formState: { isSubmitting },
  } = methods;

  const data = watch();

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <AuthHelper />
          <ErrorBanner />
        </Stack>
        <Box>
          <Stack spacing={2}>
            <RHFTextFieldForm name="email" placeholder="Enter your Email" />
            <Password name="password" placeholder="Password" />
            <ChoosePlan />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <FormGroup>
              {" "}
              <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} />}
                label="Remember Me"
              />{" "}
            </FormGroup>

            {/* <RHFCheckbox name="remember" label="Remember me" /> */}
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATH_AUTH.resetPassword}
            >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            name="login"
          >
            Log In
          </LoadingButton>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", mt: 3 }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography sx={{ mx: 2, whiteSpace: "nowrap", color: "gray" }}>
            or
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 3,
            width: "100%",
          }}
        >
          {/* <button
            className="buttonNew" // Apply both classes
          >
            <Iconify icon={"logos:google-icon"} sx={{ mr: 2 }} />
            Continue with Google
          </button> */}
          <GoogleOAuthButton
            plan={"binary"}
            buttonLabel="register.google_login"
          />
          <button
            className="buttonNew" // Apply both classes
          >
            <Iconify icon={"logos:apple"} sx={{ mr: 3 }} />
            Continue with Apple
          </button>{" "}
        </Box>
      </FormProvider>

      <EnterOtp secret={secret} data={data} />
    </>
  );
};

export default LoginForm;
