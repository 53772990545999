import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoginForm from "src/pages/auth/Login/components/MainSection/login";
import Message from "./components/message";
import NoAccountSection from "./components/no-account-section";
import AuthLayout from "./components/auth-layout";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const MainSection = () => {
  return (
    // <Container maxWidth="sm">
    //   <ContentStyle>
    <AuthLayout>
      <Message />

      <LoginForm />

      <NoAccountSection />
    </AuthLayout>
    //   </ContentStyle>
    // </Container>
  );
};

export default MainSection;
