import { styled } from "@mui/material/styles";
import Logo from "src/components/Logo";
import GetStartedTitle from "./GetStartedTitle";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { Box } from "@mui/material";

const Wrapper = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const LogoWrapper = styled("div")({
  flexGrow: 1, // Ensures the logo stays at the start
  display: "flex",
  alignItems: "center",
});

const ActionsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px", // Adds space between LanguagePopover and GetStartedTitle
});

const Header = (register) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ActionsWrapper>
        <Box sx={{ mt: -2 }}>
          <LanguagePopover />
        </Box>
        <GetStartedTitle register={register} />
      </ActionsWrapper>
    </Wrapper>
  );
};

export default Header;
