import { useRoutes } from "react-router-dom";
import ViewPDF from "src/components/pdf-viewer";
import useAuth from "src/hooks/useAuth";
import genAdmin from "./admin";
import auth from "./auth";
import main from "./main";
import user from "./user";

const Router = () => {
  const { isSubAdmin } = useAuth();

  return useRoutes(getRoutes(isSubAdmin));
};
const getRoutes = (isSubAdmin) => {
  const routes = [
    ...auth,
    ...user,
    // ...genAdmin(isSubAdmin),
    ...main,
    {
      path: "/document/:name",
      element: <ViewPDF />,
    },
  ];

  return routes;
};

export default Router;
