import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Iconify from "src/components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import { IconButtonAnimate } from "src/components/animate";
import LANGS from "src/hooks/LANGS";

export default function LanguagePopover() {
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const currentLang = useMemo(() => {
    return LANGS.find(({ value }) => value === i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  const onClick = (locale) => {
    i18n.changeLanguage(locale);
  };
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer", mr: 2 }}
        onClick={handleOpen}
      >
        <IconButtonAnimate
          sx={{
            width: 40,
            height: 40,
            ...(open && { bgcolor: "action.selected" }),
          }}
        >
          <img
            style={{
              borderRadius: "8px",
            }}
            disabledEffect
            src={currentLang?.icon}
            alt={currentLang?.label}
          />
        </IconButtonAnimate>

        <Typography
          sx={{
            color: theme.palette.grey[500],
            ml: "0 !important",
            fontSize: "13px",
            display: { md: "flex", sm: "none", xs: "none" }, // Use flex for alignment
            alignItems: "center", // Vertically center align items
            gap: "4px", // Add spacing between text and icon
          }}
        >
          {currentLang?.label}
          <Iconify icon="lsicon:down-filled" color="gray" />
        </Typography>
      </Box>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onClick(option.value);
                handleClose();
              }}
            >
              <ListItemIcon>
                <img
                  disabledEffect
                  alt={option.label}
                  src={option.icon}
                  sx={{ width: 28, mr: 2, borderRadius: 0.3 }}
                />
              </ListItemIcon>

              <ListItemText sx={{ fontSize: "12px" }} primary={option.label} />
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
