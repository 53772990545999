import { Box, Stack, Typography } from "@mui/material";
import { DOMAIN_NAME } from "src/config";
const Message = () => (
  <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4"> WELCOME BACK!</Typography>
      <Typography variant="subtitle2" gutterBottom>
        Sign in to {DOMAIN_NAME}
      </Typography>
    </Box>
  </Stack>
);

export default Message;
