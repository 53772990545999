import { Box, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import useResponsive from "src/hooks/useResponsive";
import { NavListRoot } from "./NavList";

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  key,
  navConfig,
  isCollapse = false,
  ...other
}) {
  const isDesktop = useResponsive("up", "lg");

  return (
    <Box
      {...other}
      sx={{
        overflow: "visible",
        pt: 3,
      }}
    >
      {navConfig.map((group, i) => {
        return (
          <List key={i} disablePadding sx={{ px: 2, overflow: "visible" }}>
            {group.items.map((list) => {
              return (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              );
            })}
          </List>
        );
      })}
    </Box>
  );
}
