import Page from "src/components/Page";
import RootStyle from "../shared/rootStyle";
import Header from "./components/Header";
import ImageCard from "./components/ImageCard";
import MainSection from "./components/MainSection";
import bg from "src/images/LognBG.png";
import { styled } from "@mui/material";
const Login = () => {
  const isSubAdmin = localStorage.getItem("isSubAdmin") === "true";

  if (isSubAdmin) {
    const menu_lists = JSON.parse(localStorage.getItem("menu"));
    const [menu] = menu_lists;
    const { path, children } = menu?.items[0] || {};

    if (path.includes("dashboard")) {
      window.location = children[0]?.path;
      return;
    }
    window.location = path;
    return;
  }
  const RootStyle = styled("div")(({ theme }) => ({
    minHeight: "100vh", // Full viewport height
    backgroundImage: `url(${bg})`, // Use `url()` for the background image
    backgroundSize: "cover", // Ensure the image is displayed at its actual size
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat", // Prevent the image from repeating
    backgroundColor: "#fff",
  }));

  return (
    <Page title="Login">
      <RootStyle className="dd">
        <Header />
        {/* <ImageCard /> */}
        <MainSection />
      </RootStyle>
    </Page>
  );
};

export default Login;
