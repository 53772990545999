import { Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Ternary from "src/components/ternary";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = ({ register }) => {
  const smUp = useResponsive("up", "sm");
  return (
    <Ternary
      when={register.register === "true"}
      then={
        smUp && (
          <Typography variant="body2" sx={{ mt: { md: -2 } }}>
            Already have an account&nbsp;?&nbsp;
            <Link
              variant="subtitle2"
              to={PATH_AUTH.login}
              component={RouterLink}
            >
              Login
            </Link>
          </Typography>
        )
      }
      otherwise={
        smUp && (
          <>
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? &nbsp;&nbsp;
              <Button variant="contained" href="/auth/register">
                Sign Up{" "}
              </Button>
            </Typography>
          </>
        )
      }
    />
  );
};

export default GetStartedTitle;
