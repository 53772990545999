import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PLANS } from "src/CONSTANTS";
import { PLAN_NAME } from "src/config";
import useAuth from "src/hooks/useAuth";
import useGetCurrency from "src/layouts/shared/header/currency-popover/hooks/use-get-currency";
import { loadPlan, usePlan, usePlanDispatch } from "src/store/plan";
import * as Yup from "yup";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
  plan: null,
};

const useLogin = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const fetchCurrency = useGetCurrency();

  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setError, handleSubmit, setValue } = methods;
  const [secret, setSecret] = useState(null);

  useEffect(() => {
    setValue("plan", plan ? plan : PLANS.binary);
  }, [plan]);

  const onSubmit = async (inputData) => {
    const { status, data, message, secret } = await login(inputData);
    if (!PLAN_NAME) {
      dispatch(loadPlan(inputData.plan));
      localStorage.setItem("plan", inputData.plan);
    }
    if (status) {
      enqueueSnackbar(`Welcome ${data}`);
      fetchCurrency();
    } else if (secret) {
      setValue("secret", secret);
      setSecret(secret);
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };

  return { secret, onSubmit: handleSubmit(onSubmit), methods };
};

export default useLogin;
