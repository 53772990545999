import { IconButton, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import RHFTextFieldForm from "./hook-form/RHFTextFieldForm";

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RHFTextFieldForm
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Iconify
                icon={
                  showPassword ? "lets-icons:eye-light" : "quill:eye-closed"
                }
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

Password.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Password;
