import { TextField, FormHelperText, Box } from "@mui/material";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

RHFTextFieldForm.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextFieldForm({
  name,
  sx,
  label,
  placeholder,
  ...other
}) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px", // Border radius to round the corners
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#f5f5f5", // Set the border color to match the background
                },
                "&:hover fieldset": {
                  borderColor: "#f5f5f5", // Keep the border color same on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#f5f5f5", // Keep the border color same when focused
                },
              },
            }}
            {...field}
            fullWidth
            error={!!error}
            placeholder={t(placeholder)}
            label={t(label)} // Uncommented to display the label
            {...other}
          />

          {error && (
            <FormHelperText style={{ marginLeft: 4, marginTop: 8 }} error>
              {t(error?.message)}
            </FormHelperText> // Correctly display the error message
          )}
        </>
      )}
    />
  );
}
