import { Box, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AuthLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        py: isMobile ? "80px" : "150px", // Adjust padding for mobile view
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        fixed
        maxWidth="sm"
        sx={{
          height: "100%",
          textAlign: "center",
          maxWidth: "500px", // Set a maximum width for the container
        }}
      >
        {isMobile ? (
          // Mobile View - Render children directly
          <>{children}</>
        ) : (
          // Desktop and Tablet View
          <Box
            sx={{
              padding: "25px 60px 40px",
              boxShadow: `3px 6px 15px 2px rgba(0, 0, 0, 0.1)`,
              borderRadius: "12px",
              backgroundColor: "#fff",
              maxWidth: "500px", // Set a maximum width for the desktop layout
              margin: "0 auto", // Center-align content horizontally
            }}
          >
            <Box
              sx={{
                display: "flex",
                mb: 3,
                justifyContent: "center",
              }}
            >
              {/* Uncomment and replace logo if needed */}
              {/* <img style={{ width: "80px", height: "auto" }} src={logo} /> */}
            </Box>
            {children}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default AuthLayout;
