// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import enLocales from "./en.json";
// import esLocales from "./es.json";
// import ptLocales from "./pt.json";
// import deLocales from "./de.json";
// import jaLocales from "./ja.json";
// import itLocales from "./it.json";
// import cnLocales from "./cn.json";
// import arLocales from "./ar.json";
// import ruLocales from "./ru.json";
// import plLocales from "./pl.json";
// import trLocales from "./tr.json";
// import frLocales from "./fr.json";

// i18n.use(initReactI18next).init({
//   resources: {
//     en: enLocales,
//     es: esLocales,
//     pt: ptLocales,
//     de: deLocales,
//     ja: jaLocales,
//     it: itLocales,
//     cn: cnLocales,
//     ar: arLocales,
//     ru: ruLocales,
//     pl: plLocales,
//     tr: trLocales,
//     fr: frLocales,
//   },
//   lng: "en",

//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
});
export default i18n;
