import { Box, Button, Card, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SentIcon } from "src/assets";
import Page from "src/components/Page";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import { PATH_AUTH } from "src/routes/paths";
import { ResetPasswordForm } from "src/sections/auth/reset-password";
import axiosInstance from "src/utils/axios";
import bg from "src/images/LognBG.png";
import Header from "./Login/components/Header";
import AuthLayout from "./Login/components/MainSection/components/auth-layout";

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: "100vh", // Full viewport height
  backgroundImage: `url(${bg})`, // Use `url()` for the background image
  backgroundSize: "cover", // Ensure the image is displayed at its actual size
  backgroundPosition: "center", // Center the image
  backgroundRepeat: "no-repeat", // Prevent the image from repeating
  backgroundColor: "#fff",
}));

const SendEmailSection = ({ loading, onSent, validationError }) => {
  return (
    <>
      <Typography variant="h4" paragraph>
        FORGOT PASSWORD?
      </Typography>
      <Typography sx={{ color: "text.secondary", mb: 3 }}>
        No worries, we will send your reset instructions
      </Typography>

      <Typography sx={{ color: "text.secondary", mb: 3 }}>
        Enter your Registered Email to receive on{" "}
        <span style={{ fontWeight: "bold" }}>OTP</span>{" "}
      </Typography>

      <ResetPasswordForm
        loading={loading}
        onSent={onSent}
        validationError={validationError}
      />

      <Button
        fullWidth
        size="large"
        component={RouterLink}
        to={PATH_AUTH.login}
        sx={{ mt: 1 }}
        name="reset-back"
      >
        Go Back
      </Button>
    </>
  );
};

const AfterSend = ({ mail }) => (
  <Box sx={{ textAlign: "center" }}>
    <SentIcon sx={{ mb: 5, mx: "auto", height: 160 }} />

    <Typography variant="h3" gutterBottom>
      Request sent successfully
    </Typography>
    <Typography>
      We have sent a confirmation email to &nbsp;
      <strong>{mail}</strong>
      <br />
      Please check your email.
    </Typography>

    <Button
      size="large"
      variant="contained"
      component={RouterLink}
      to={PATH_AUTH.login}
      sx={{ mt: 5 }}
    >
      Back
    </Button>
  </Box>
);

export default function ResetPassword() {
  const [sent, setSent] = useState(false);
  const [sentMailId, setSentMailId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [validationError, setValidationError] = useState();
  const [loading, setLoading] = useState(false);
  const sendResetRequest = async (email) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    setValidationError();
    try {
      const res = await axiosInstance.post("/api/forgot-password", formData, {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });
      if (res.status === 200) {
        setLoading(false);

        setSentMailId(email);
        setSent(true);
      }
    } catch (err) {
      setLoading(false);
      setValidationError(err.message);
    }
  };

  return (
    <AuthWraper>
      {sent ? (
        <AfterSend mail={sentMailId} />
      ) : (
        <SendEmailSection
          loading={loading}
          onSent={sendResetRequest}
          validationError={validationError}
        />
      )}
    </AuthWraper>
  );
}

export const AuthWraper = ({ children }) => {
  return (
    <Page title="Reset Password" sx={{ height: 1 }}>
      <RootStyle>
        <Header />
        <AuthLayout>
          <Box sx={{ maxWidth: 480, mx: "auto" }}>{children}</Box>
        </AuthLayout>
      </RootStyle>
    </Page>
  );
};
